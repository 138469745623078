import { axios } from "@/config/axios";
import { googleLogout } from "vue3-google-login";
import {
  login as logingRoute,
  register,
  userDetail,
  socialLogin,
  forgotPassword,
  resetPassword,
  activateAcctount,
  activateAcctountByEmail, getUserDetail,
} from "@/endpoints";

import { toRaw } from "vue";
import { notify } from "@kyvg/vue3-notification";
import { STATE_STATUSES } from "@/helpers/constants";
import { router } from "@/router/index";
import {track} from "@/analytics/events";
import {uuid} from "vue-uuid";
export const actions = {
  setProvider({ commit }, payload) {
    commit("setProvider", payload);
  },

  clearErrorMessage({ commit }, payload) {
    commit("setClearErrorMessage", payload);
  },

  async login({ commit, state }, payload) {
    let { redirectPath } = toRaw(state);

    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(logingRoute, payload);
      if (
          response.data?.errors.length <= 0 &&
          response.data._metadata.outcomeCode == 200
      ) {

        commit("setUser", response.data.records);
        commit("STATE_STATUSES", STATE_STATUSES.READY);
        notify({
          type: "Success",
          title: "Successfully Login",
          text: "redirecting...",
        });

        if (redirectPath) {
          router.push({
            path: redirectPath,
          });
          commit("setRedirectingPath", null);
          return;
        } else if (!redirectPath) {
          // code for segment
          track("Login Redirect to Customer Portal", {
            event_id: uuid.v4(),
          });
          router.push({
            path: '/dashboard/extend-policy-plans',
          });
          // router.go(-1);
        }
      } else if (response.data._metadata.outcomeCode == 404) {
        console.log(response.data._metadata.message)
        commit("setErrorMessage", `Incorrect password for this email. You can <a style="text-decoration: underline; "  href="/forgot">reset your password</a> or try again.`);
        /*notify({
          type: "error",
          title: "Authorization",
          text: "Email and Password is invalid",
        });*/
      } else {
        response.data?.errors.map((error) => {
          notify({
            type: "error",
            title: "Authorization",
            text: "Email and Password is invalid",
          });
        });
      }
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
      notify({
        type: "error",
        title: "Authorization",
        text: "Unknown error occurred. Please try again.",
      });
    }
  },
  async socialLogin({ commit, state }, payload) {
    let { redirectPath } = toRaw(state);

    // commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(socialLogin, payload);
      if (
          response.data?.errors.length <= 0 &&
          response.data._metadata.outcomeCode == 200
      ) {
        commit("setUser", response.data.records);
        // commit("STATE_STATUSES", STATE_STATUSES.READY);
        notify({
          type: "Success",
          title: "Successfully Login",
          text: "redirecting...",
        });
        if (redirectPath) {
          router.push({
            path: redirectPath,
          });
          return;
        } else if (!redirectPath) {
          router.go(-1);
        }
      } else if (response.data._metadata.outcomeCode == 404) {
        notify({
          type: "error",
          title: "Authorization",
          text: "Email and Password is invalid",
        });
      } else {
        response.data?.errors.map((error) => {
          notify({
            type: "error",
            title: "Authorization",
            text: "Email and Password is invalid",
          });
        });
      }
      // commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      // commit("STATE_STATUSES", STATE_STATUSES.ERROR);
      notify({
        type: "error",
        title: "Authorization",
        text: "Unknown error occurred. Please try again.",
      });
    }
  },

  async signup({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(register, payload);
      if (
          response.data?.errors.length <= 0 &&
          response.data?._metadata.outcomeCode === 200
      ) {
        commit("setUser", response.data.records);
        notify({
          type: "Success",
          title: "Successfully Signup",
          text: "redirecting...",
        });
        // router.push("/request-activate");
        router.push("/dashboard/extend-policy-plans");
      } else if (response.data?._metadata.outcomeCode === 404) {
        notify({
          type: "error",
          title: "Authorization",
          text: response.data._metadata.message,
        });
      } else {
        response.data?.errors.map((error) => {
          notify({
            type: "error",
            title: "Authorization",
            text: error.code,
          });
        });
      }
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
      notify({
        type: "error",
        title: "Authorization",
        text: "Unknown error occurred. Please try again.",
      });
    }
  },

  async logout({ commit, state }, payload) {
    let { provider } = toRaw(state);
    if (provider) {
      if (provider.provider == "google") {
        googleLogout(provider.access_token);
      }
      if (provider.provider == "facebook") {
        await window.FB.logout((response) => {
          commit("setProvider", null);
          console.log("fb response inside", response);
        }, provider.access_token);
      }
    }

    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      commit("logout");
      notify({
        type: "Success",
        title: "Successfully Logout",
        text: "redirecting...",
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
      // router.push("/");
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async forgotPassword({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(forgotPassword, payload);

      if (
          response.data?.errors.length <= 0 &&
          response.data._metadata.outcomeCode == 200
      ) {
        notify({
          type: "Success",
          title: "Email Generated",
          text: "Email has been sent to your email address.",
        });
        router.push("/reset-password-email-conformation");

      } else if (response.data._metadata.outcomeCode == 404) {
        notify({
          type: "error",
          title: "Email not generated",
          text: response.data._metadata.message,
        });
      } else {
        response.data?.errors.map((error) => {
          notify({
            type: "error",
            title: "Error",
            text: error.code,
          });
        });
      }
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async resetPassword({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(resetPassword, payload);

      if (
          response.data?.errors.length <= 0 &&
          response.data._metadata.outcomeCode == 200
      ) {
        notify({
          type: "Success",
          title: "Password Updated",
          text: "Password has been updated",
        });
        commit("setRedirectingPath", "/");
        router.push("/login");
      } else if (response.data._metadata.outcomeCode == 404) {
        notify({
          type: "error",
          title: "Password Not Updated",
          text: response.data._metadata.message,
        });
      } else {
        response.data?.errors.map((error) => {
          notify({
            type: "error",
            title: "Error",
            text: error.code,
          });
        });
      }
      commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
      notify({
        type: "error",
        title: "Bad Happen",
        text: response.data._metadata.message,
      });
    }
  },

  async setIsComingFrom({ commit }, payload) {
    commit("setIsComingFrom", payload);
  },

  async resetIsComingFrom({ commit }) {
    commit("resetIsComingFrom");
  },

  async activateAcc({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(activateAcctount(payload));

      commit("STATE_STATUSES", STATE_STATUSES.READY);
      if (response.data.records.is_active) {
        notify({
          type: "Success",
          title: "Your account activated",
          text: "redirecting...",
        });
        commit("activeUser", response.data.records);
        router.push("/login");
      }

      return;
    } catch (e) {
      console.log("error", e);
      notify({
        type: "error",
        title: `${e}`,
        text: "Unknown error occurred. Please try again.",
      });
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },
  async activateAcctountByEmailCall({ commit }, payload) {
    commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    try {
      const response = await axios.post(activateAcctountByEmail, {
        email: payload,
      });
      commit("STATE_STATUSES", STATE_STATUSES.READY);
      if (response.data?.records?.email_send) {
        notify({
          type: "Success",
          title: "Activation link emailed.",
          text: "redirecting...",
        });
        setTimeout(() => {
          router.push("/login");
        }, 3000);
        return;
      }

      if (response.data?.errors) {
        notify({
          type: "error",
          title: `Account activation error`,
          text: `${response.data?.errors}`,
        });

        return;
      }
    } catch (e) {
      console.log("error", e);
      notify({
        type: "error",
        title: `${e}`,
        text: "Unknown error occurred. Please try again.",
      });
      commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  async setRedirectingPath({ commit }, payload) {
    commit("setRedirectingPath", payload);
  },

  async getUserDetail({ commit, state }, payload) {
    // commit("STATE_STATUSES", STATE_STATUSES.PENDING);
    //  console.log('user payload',payload)
    try {
      const response = await axios.get(getUserDetail, {
        id:payload,
      });
      // console.log('Manoj bro',response.data.records)
      commit("setUser", response.data.records);
      /*if (
          response.data?.errors.length <= 0 &&
          response.data._metadata.outcomeCode == 200
      ) {

        // commit("STATE_STATUSES", STATE_STATUSES.READY);
      }*/
      // commit("STATE_STATUSES", STATE_STATUSES.READY);
    } catch (e) {
      console.log("error", e);
      // commit("STATE_STATUSES", STATE_STATUSES.ERROR);
    }
  },

  // setLoggInUserData on thank you page
  async setLoggInUserData({ commit }, payload) {
    commit("setUser",payload);
  },

};
