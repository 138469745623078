import { toRaw } from "vue";
import {billingState} from "@/composable/vuelidate";


export const ISO3 = (countries, countryName) => {
  return toRaw(countries.value).find(({ name }) => name == countryName).iso3;
};
export const ISO2 = (countries, countryName) => {
  return toRaw(countries.value).find(({ name }) => name == countryName).iso2;
};
export const getStatesOfCountry = (countries, countryName) => {
  let getCountry = toRaw(countries.value).filter(
    ({ name }) => name == countryName
  );

  if (!getCountry.length) return []; // Handle case where country is not found
  let { states } = getCountry[0];

  
  return states.sort((a, b) => a.name.localeCompare(b.name));  // Sort states by name before returning
};

export const getStatesOfCountryByISO2 = (countries, countryIso2) => {
  let getStatesFromCountry = toRaw(countries.value).filter(
      ({ iso2 }) => iso2 == countryIso2
  )
  if (!getStatesFromCountry.length) return [];
  // Handle case where country is not found
  let { states } = getStatesFromCountry[0];
  return states.sort((a, b) => a.name.localeCompare(b.name));  // Sort states by name before returning
};

export const matchesStateOfCountry = (countries,countryIso,stateIso) => {
  // Find the country with matching ISO2 code
  const stateCountry =  toRaw(countries.value).filter(c => c.iso2 === countryIso);
  
  // Check if country exists and has states
  if (!stateCountry.length) return false;
  let { states } = stateCountry[0];
  
  // Check if any state in the country matches the state ISO code
  return states.some(state => state.iso2 === stateIso);
};

export const ISO3ToFullName = (countries, comingIso3) => {
  // return toRaw(countries.value).find(({ iso3 }) => iso3 == comingIso3).name;
};

export const ISO2ToFullName = (countries, comingIso2) => {
  return countries.find(({ iso2 }) => iso2 == comingIso2).name
};
