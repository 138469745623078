import { customerInfo, eventId } from "./customerInfo";
import { uuid } from "vue-uuid";

const identify = (customerId, traits = {}) => {
    if (typeof window !== "undefined") {
        window?.analytics?.identify(customerId, traits);

        // clarity
        const { email, first_name, last_name } = traits;
        const anonymousId = window.analytics.user().anonymousId();
        window.clarity('identify', email, anonymousId, window.location, `${first_name} ${last_name}`);
    }
}

const alias = (customerId, originalId = null) => {
    if (typeof window !== "undefined") {
        if(originalId) {
            window?.analytics?.alias(customerId, originalId);
        } else {
            window?.analytics?.alias(customerId);
        }

    }
}

const track = (event, properties) => {
    // Get some data from session or local storage if available
    let updatedProperties = properties;

    if (typeof window !== "undefined") {
        try {
            // Check if user method exists and is a function
            const customerType = (typeof window.analytics.user === 'function')
                ? window.analytics.user().traits().customerType
                : undefined;

            const propertyOverrides = {
                customer_type: customerType,
            };

            updatedProperties = {
                ...properties,
                ...propertyOverrides,
                event_id: uuid.v4(),
                customer_info: customerInfo()
            };
        } catch (err) {
            console.error("Error retrieving customer traits:", err);
        }

        // Ensure that the track method exists before calling it
        if (typeof window.analytics.track === 'function') {
            window.analytics.track(event, updatedProperties);
        } else {
            console.error("Track method is not available on analytics object");
        }

        // clarity
        window.clarity("event", event);
    }
}

export {
    alias,
    identify,
    track
}
